import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//import product image
/* import productDetailImage from "../../images/product-detail-image.png";
import pustakariDetail from "../../images/pustakari-final.png";
import oatmealImage from "../../images/oatmeal-final.png";
import semolinaImage from "../../images/semolina-final.png";
import gundpakImage from "../../images/gundpak-final.png";
import luxuryImage from "../../images/luxury-box-final.png";
import smallImage from "../../images/small-box-final.png"; */

//import banner image
/* import chocolateFudgeBanner from "../../images/chocolate-fudge-banner.png";
import pustakariBanner from "../../images/pustakari-banner.png";
import oatmealBanner from "../../images/oatmeal-banner.png";
import semolinaBanner from "../../images/semolina-banner-thumb.jpg";
import sugarFreeBanner from "../../images/sugar-free-gundpak.jpg";
import twoJarBanner from "../../images/two-jar-banner.png";
import threeJarBanner from "../../images/3-jar-gundpak-combo.jpg";
import sevenPackBanner from "../../images/21-pack-banner.png";
import twentyOneBanner from "../../images/21-pack-thumb-banner.jpg";
import luxuryBanner from "../../images/banner-image.png";
import smallBanner from "../../images/small-box-thumb-banner.jpg";
import roseWaterGundpak from "../../images/rosewater-gundpak1.jpg";
import mochaGundpakLatest from "../../images/mocha-gundpak-latest.jpg"; */
// import components
import AvailableBundles from "../../Components/AvailableBundles";
import ShopModal from "../../Components/ShopModal";
import SecondaryBanner from "../../Components/SecondaryBanner";

const ProductDetail = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([
    {
      id: "chocolate-fudge",
      description:
        "Handcrafted dark chocolate fudge cookies filled with the saccharine taste of Gundpak.",
      ingredients:
        "Butter, Sugar, Brown Sugar, Flour, Baking Powder, Egg, Salt, Cocoa Powder, Sunflower Oil",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/product-detail-image.png`,
      hasBundles: true,
    },
    {
      id: "pustakari",
      description: `Delicious hard candies that will take you back in time, reminiscing days of Nepal's recent but never forgotten authentic delicious history. The small box has around 30 candies and the large box contains around 50 Pieces of this premium candy.`,
      ingredients: "Sugar, Lemon juice, Khuwa",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/pustakari-final.png`,
      hasBundles: true,
    },
    {
      id: "oatmeal",
      description:
        "Handcrafted oatmeal cookies with Pumpkin seeds, Cranberry and an infusion of Gundpak.",
      ingredients:
        "Flour, Oats, Cranberry, Butter, Baking Soda, Baking Powder, Egg, Salt, Brown Sugar, Vanilla, Pumpkin Seed, Cinnamon Powder",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-final.png`,
      hasBundles: true,
    },
    {
      id: "semolina",
      description:
        "Wholesome Semolina cookies handcrafted and filled with delectable Gundpak.",
      ingredients:
        "Semolina, Butter, Sugar, Egg, Vanilla, Baking Powder, Lemon Juice",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-final.png`,
      hasBundles: true,
    },
    {
      id: "mocha",
      description:
        "A play with the authentic Gundpak recipe, our Gundpaks have the wholesome taste of nostalgia. This is a traditional gundpak infused with a hit of coffee.",
      ingredients: "Khuwa, Coffee, Sugar, Gund, Watermelon Seeds, Milk",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-final.png`,
      hasBundles: true,
    },
    {
      id: "rosewater",
      description:
        "A play with the authentic Gundpak recipe, our Gundpaks have the wholesome taste of nostalgia. This is a traditional gundpak infused with modern touch of rosewater and garnished with pistachio.",
      ingredients: "Khuwa, Milk, Rosewater, Sugar, Pistachio, Gund",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-final.png`,
      hasBundles: true,
    },
    {
      id: "sugar-free",
      description:
        "Sugar Free Gundpak is made by stirring a big pan of sweetening agent, khuwa, peanut powder and coconut powder into a brown gooey batter. This is an appropriate product for diabetic people.",
      ingredients: "Khuwa, Diet Sugar, Watermelon Seeds, Gund, Milk",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-final.png`,
      hasBundles: true,
    },
    {
      id: "two-jar",
      description:
        'What is better than a jar of Gundpak? "The Two Jar Combo" is the answer.. You can choose between our Mocha Gundpak and our Rosewater and Pistachio Gundpak.',
      subHead: "Two Jar Of Gundpak: Rs. 1249",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-final.png`,
      hasBundles: true,
    },
    {
      id: "three-jar",
      description: `The three jar of gundpak combo is a combination of our three delectable gundpak jars, Rosewater & Pistachio Gundpak, Mocha Gundpak and Sugar-free Gundpak. You can choose from these three flavors to form the combination.`,
      subHead: "Three jar of gundpak: Rs. 2249",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-final.png`,
      hasBundles: true,
    },
    {
      id: "seven-pack",
      description:
        "The 7 pack cookies standardly has 7 small cookies of any flavor among the three recipes. The box can be set up customarily with cookies of your choice.",
      subHead: "7 Pack of Cookies: Rs. 500",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/product-detail-image.png`,
      hasBundles: true,
    },
    {
      id: "fourteen-pack",
      description:
        "The 14 pack cookies standardly has 14 small pieces of either of our three cookies’ recipes, which are Gundpak filled Semolina cookies, Choco-fudge cookies and Oatmeal Cranberry cookies with Gundpak. You can customarily choose the number of each cookie type to make a combination.",
      subHead: "14 Pack of Cookies: Rs. 1149",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/product-detail-image.png`,
      hasBundles: true,
    },
    {
      id: "21-pack",
      description:
        "The 21 pack cookies standardly has 7 small pieces of each of our three cookies’ recipe which are Gundpak filled Semolina cookies, Choco-fudge cookies and Oatmeal Cranberry cookies with Gundpak. You can customarily choose the number of each cookie type.",
      subHead: "21 Pack of Cookies: Rs. 1599",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/product-detail-image.png`,
      hasBundles: true,
    },
    {
      id: "luxury-box",
      description:
        "Ultimate luxury wrapped in a box, feels like a warm hug of Nepali authenticity. Combination of 1 rosewater gundpak, 1 mocha gundpak, 1 sugar free gundpak, one big box pustakari, 6 cookies(of your choice) and a handcrafted pure copper spoon.",
      subHead: "The Ultimate Luxury Box: Rs. 4499",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/luxury-box-final.png`,
      hasBundles: false,
    },
    {
      id: "small-gift",
      description:
        "The small gift box is a package of 4 small cookies of choice, 1 small box of Pustakari and 1 jar of Gundpak of your choice. All these come in a box, which is very handy, perfect for gifting and a pure copper spoon is included in the box.",
      subHead: "The Small Gift Box: Rs. 2599",
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/small-box-final.png`,
      hasBundles: false,
    },
  ]);
  const [renderedProducts, setRenderedProducts] = useState([]);
  const [secondayBanner, setSecondayBanner] = useState({});

  useEffect(() => {
    switch (id) {
      case "product-chocolate-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "chocolate-fudge")
        );
        document.title = 'Chocolate Fudge Cookies | Makkusé"';
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Chocolate Fudge Gundpak Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-banner.png`,
        });
        break;
      case "product-pustakari-detail":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "pustakari")
        );
        document.title = 'Milk Pustakari | Makkusé"';
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Milk Pustakari",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/pustakari-banner.png`,
        });
        break;
      case "product-oatmeal-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "oatmeal")
        );
        document.title = 'Oatmeal Gundpak Cookies | Makkusé"';
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Oatmeal Gundpak Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-banner.png`,
        });
        break;
      case "product-semolina-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "semolina")
        );
        document.title = "Gundpak Filled Semolina Cookies | Makkusé";

        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Gundpak Filled Semolina Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/semolina-banner-thumb.jpg`,
        });
        break;
      case "product-mocha-gundpak":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "mocha")
        );
        document.title = "Mocha Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Mocha Gundpak",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/mocha-gundpak-latest.jpg`,
        });
        break;
      case "product-rosewater-gundpak":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "rosewater")
        );
        document.title = "Rosewater & Pistachio Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Rosewater & Pistachio Gundpak",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/rosewater-gundpak1.jpg`,
        });
        break;
      case "product-sugarfree-gundpak":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "sugar-free")
        );
        document.title = "Sugar Free Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Sugar Free Gundpak",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/sugar-free-gundpak.jpg`,
          page: "sugarfree",
        });
        break;
      case "product-combo-2-jar":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "two-jar")
        );
        document.title = "Two Jar Of Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Two Jar Of Gundpak",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/two-jar-banner.png`,
        });
        break;
      case "product-combo-three-jar":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "three-jar")
        );
        document.title = "Three Jar Of Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Three Jar Of Gundpak",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/3-jar-gundpak-combo.jpg`,
        });
        break;
      case "product-combo-7-pack-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "seven-pack")
        );
        document.title = "7 Pack Cookies | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "7 Pack Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/seven-pack-banner.jpg`,
        });
        break;
      case "product-combo-14-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "fourteen-pack")
        );
        document.title = "14 Pack Cookies | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "14 Pack Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/fourteen-pack-banner.jpg`,
        });
        break;
      case "product-combo-21-pack-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "21-pack")
        );
        document.title = "21 Pack Cookies | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "21 Pack Cookies",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/21-pack-thumb-banner.jpg`,
        });
        break;
      case "product-luxury-box-detail":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "luxury-box")
        );
        document.title = "The Ultimate Luxury Box | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "The Ultimate Luxury Box",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/banner-image.png`,
        });
        break;
      case "product-small-gift-box-detail":
        setRenderedProducts(() =>
          products.filter((product) => product.id === "small-gift")
        );
        document.title = "Small Gift Box | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Small Gift Box",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/small-box-thumb-banner.jpg`,
          page: "small-gift",
        });
        break;
      default:
        return;
    }
  }, [id]);

  return (
    <main className="page-product-details">
      <SecondaryBanner bannerInfo={secondayBanner} />
      <section className="section-product-details">
        {renderedProducts.map((product) => {
          return (
            <div className="container product" key={product.id}>
              <div className="row">
                <div className="col-md-7">
                  <div className="text-container">
                    <div className="para">
                      <p>{product.description}</p>
                    </div>
                    {product.ingredients && (
                      <div className="sub-head">
                        <p className="sub-title">Ingredients:</p>
                      </div>
                    )}
                    {product.subHead && (
                      <div class="sub-head">
                        <h4>{product.subHead}</h4>
                      </div>
                    )}
                    {product.ingredients && (
                      <p className="caption">{product.ingredients}</p>
                    )}
                    {product.id === "luxury-box" && (
                      <>
                        <div className="sub-head" style={{ marginTop: "46px" }}>
                          <p className="sub-title">Contains</p>
                        </div>
                        <p className="caption">
                          1 Jar Rosewater and Pistachio Gundpak
                        </p>
                        <p className="caption">
                          1 Jar Mocha Gundpak | 1 Jar Sugarfree Gundpak
                        </p>
                        <p className="caption">
                          1 Big Box of Pustakari | 6 Cookies of your choice
                        </p>
                        <p className="caption">A pure copper spoon</p>
                      </>
                    )}
                    {product.id === "small-gift" && (
                      <>
                        <div className="sub-head" style={{ marginTop: "46px" }}>
                          <p className="sub-title">Contains</p>
                        </div>
                        <li className="caption">A Gudpak Jar of choice</li>
                        <li className="caption">Large cookies &</li>
                        <li className="caption">A copper spoon</li>
                      </>
                    )}
                  </div>
                  <div className="icon-section">
                    <h5>Share:</h5>
                    <div className="icon-container">
                      <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdev.makkuse.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="21"
                          height="22"
                          viewBox="0 0 21 22"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M18.3 0.5H2.2C1.61722 0.499999 1.05824 0.731235 0.645778 1.14295C0.233312 1.55467 0.00105961 2.11322 0 2.696L0 18.804C0 19.3875 0.231785 19.9471 0.644365 20.3596C1.05695 20.7722 1.61652 21.004 2.2 21.004H8.48V14.03H5.594V10.75H8.477V8.25C8.477 5.406 10.17 3.835 12.763 3.835C13.6143 3.84664 14.4635 3.9205 15.304 4.056V6.85H13.873C13.6291 6.81752 13.3811 6.84031 13.1472 6.9167C12.9134 6.99309 12.6997 7.12111 12.522 7.29129C12.3444 7.46146 12.2073 7.66943 12.1209 7.89978C12.0345 8.13013 12.001 8.37696 12.023 8.622V10.75H15.17L14.67 14.03H12.023V21H18.3C18.8835 21 19.4431 20.7682 19.8556 20.3556C20.2682 19.9431 20.5 19.3835 20.5 18.8V2.696C20.4989 2.11322 20.2667 1.55467 19.8542 1.14295C19.4418 0.731235 18.8828 0.499999 18.3 0.5Z" />
                        </svg>
                      </a>
                    </div>

                    <div className="icon-container">
                      <a
                        href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdev.makkuse.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="22"
                          height="23"
                          viewBox="0 0 21 22"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M18.3239 0.5H2.22393C1.64114 0.499999 1.08217 0.731235 0.669703 1.14295C0.257238 1.55467 0.0249854 2.11322 0.0239258 2.696L0.0239258 18.804C0.0239258 19.3875 0.255711 19.9471 0.668291 20.3596C1.08087 20.7722 1.64045 21.004 2.22393 21.004H18.3239C18.9074 21.004 19.467 20.7722 19.8796 20.3596C20.2921 19.9471 20.5239 19.3875 20.5239 18.804V2.696C20.5229 2.11322 20.2906 1.55467 19.8781 1.14295C19.4657 0.731235 18.9067 0.499999 18.3239 0.5ZM16.0899 7.767C16.0989 7.895 16.0989 8.028 16.0989 8.156C16.1068 9.27955 15.8913 10.3935 15.4649 11.433C15.0386 12.4725 14.4099 13.417 13.6154 14.2115C12.8209 15.006 11.8765 15.6346 10.8369 16.061C9.79738 16.4873 8.68347 16.7028 7.55993 16.695C5.92694 16.6985 4.32751 16.2316 2.95293 15.35C3.19302 15.3761 3.43442 15.3885 3.67593 15.387C5.02615 15.39 6.33816 14.9389 7.40093 14.106C6.77472 14.0946 6.16774 13.8877 5.66482 13.5145C5.16191 13.1412 4.7882 12.6201 4.59593 12.024C5.04608 12.1015 5.50754 12.0827 5.94993 11.969C5.27113 11.8304 4.66121 11.4612 4.22372 10.924C3.78624 10.3868 3.54817 9.71479 3.54993 9.022V8.985C3.96478 9.21716 4.42886 9.3474 4.90393 9.365C4.49243 9.09069 4.15513 8.71891 3.92204 8.28274C3.68895 7.84657 3.5673 7.35954 3.56793 6.865C3.56446 6.3328 3.70436 5.80948 3.97293 5.35C4.72636 6.27789 5.66658 7.03688 6.73249 7.57768C7.79841 8.11848 8.96618 8.42898 10.1599 8.489C10.0115 7.84518 10.0791 7.1704 10.3525 6.56887C10.6258 5.96734 11.0896 5.47254 11.6721 5.16088C12.2547 4.84921 12.9237 4.73804 13.5758 4.84452C14.2279 4.951 14.8267 5.26921 15.2799 5.75C15.9504 5.62112 16.5934 5.37647 17.1799 5.027C16.9561 5.71961 16.4875 6.30692 15.8619 6.679C16.4588 6.61123 17.042 6.45379 17.5919 6.212C17.1843 6.81279 16.6762 7.33883 16.0899 7.767Z" />
                        </svg>
                      </a>
                    </div>
                    <div className="icon-container">
                      <a
                        href="https://www.linkedin.com/company/makkuse"
                        target="blank"
                        rel="noreferrer"
                      >
                        <div className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="img"
                            width="23"
                            height="23"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="image-section">
                    <div className="image-container">
                      <img src={product.imageSrc} alt={product.productName} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      {renderedProducts[0]?.hasBundles && <AvailableBundles pageId={id} />}
      <ShopModal />
    </main>
  );
};

export default ProductDetail;
