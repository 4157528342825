import React from "react";
import prev from "../images/prev.png";
import next from "../images/next.png";
export default function SlickArrow(props) {
  let className =
    props.type === "next"
      ? "slick-arrow slick-next customizedSlickArrowReact"
      : "slick-arrow slick-prev customizedSlickArrowReact";

  return (
    <button
      type="button"
      className={className}
      style={{ width: "20px" }}
      onClick={props.onClick}
    >
      <img src={props.type === "next" ? next : prev} alt="" />
    </button>
  );
}
