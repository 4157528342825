import React from "react";
import Slider from "react-slick";
import SlickArrow from "../../Components/SlickArrow";
/* import packageSlider1 from "../../images/packaging-slider-1.jpg";
import packageSlider2 from "../../images/packaging-slider-2.jpg";
import packageSlider3 from "../../images/packaging-slider-3.jpg";
import packageSlider4 from "../../images/packaging-slider-4.jpg"; */

export default function packaging({ id }) {
  // console.log(id);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    dots: true,
    arrows: true,
    infinite: true,
    prevArrow: <SlickArrow type="prev" />,
    nextArrow: <SlickArrow type="next" />,
  };
  return (
    <section className="section-packaging">
      <div className="text-center underline mb-4">
        <h2>Some Of Our Crafted Packaging</h2>
      </div>
      <div className="packaging-slider">
        {id === "product-corporate-boxes" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/packaging-slider-1.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/packaging-slider-2.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/packaging-slider-3.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/packaging-slider-4.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          </Slider>
        )}
        {id === "product-festive-gifting" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-1.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-2.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-3.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-4.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-5.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-6.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-7.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-8.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          </Slider>
        )}
      </div>
    </section>
  );
}
