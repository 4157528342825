import React from "react";
import Slider from "react-slick";
/* import client from "../../images/client.png";
import clientReegha from "../../images/client-reegha.png";
import clientHisila from "../../images/client-hisila.png";
import sliderProduct1 from "../../images/slider-product1.png";
import happyKidImage from "../../images/happy-kid.jpg"; */

import { Link } from "react-router-dom";

export default function Testimonial() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    dots: true,
    infinite: true,
    arrows: false,
  };
  return (
    <section className="section-testimonial">
      <div className="container">
        <Slider {...settings}>
          <div className="testimonial-container">
            <div className="testimonial-content">
              <div className="client-header">
                <div className="client-image">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/images/client.png`}
                    alt="Client Sudip Neupane"
                  />
                </div>
                <div className="client-quote-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="92.68"
                    height="73.38"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                    />
                  </svg>
                  <h3>Taste of Nepali Nostalgia</h3>
                </div>
              </div>
              <div className="client-quote-container">
                <div className="client-quote">
                  <p>
                    My family sent me Makkusé products as a gift after I came to
                    Australia, never had I imagined it would give me a taste of
                    Nepali nostalgia, all so wholesome. Definitely recommended.
                  </p>
                </div>
                <div className="review-head">
                  <p>
                    <strong>Sudip Neupane</strong>
                  </p>
                  <p>
                    <strong>Perth, Australia</strong>
                  </p>
                </div>
                {/* <!-- <div className="review-subhead">
        <p>Food Review Channel</p>
      </div> --> */}
                {/* <!-- <div className="review-platform">
        <p><em>Via Instagram</em></p>
      </div> --> */}
              </div>
            </div>
          </div>
          <div className="testimonial-container">
            <div className="testimonial-content">
              <div className="client-header">
                <div className="client-image">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/images/client-reegha.png`}
                    alt="Client Reegha Rajbhandari"
                  />
                </div>
                <div className="client-quote-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="92.68"
                    height="73.38"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                    />
                  </svg>
                  <h3>Exceptional Customer Service</h3>
                </div>
              </div>
              <div className="client-quote-container">
                <div className="client-quote">
                  <p>
                    Love the concept and the execution. Exceptional customer
                    service. Even as a young start up the effort you guys put to
                    deliver well to each customer is commendable. Wish you the
                    best! Nepalis now have something they can proudly take
                    abroad as a gift!
                  </p>
                </div>
                <div className="review-head">
                  <p>
                    <strong>Reegha Rajbhandari</strong>
                  </p>
                  <p>
                    <strong>Sydney, Australia</strong>
                  </p>
                </div>
                {/* <!-- <div className="review-subhead">
        <p>Food Review Channel</p>
      </div>
      <div className="review-platform">
        <p><em>Via Instagram</em></p>
      </div> --> */}
              </div>
            </div>
          </div>
          <div className="testimonial-container">
            <div className="testimonial-content">
              <div className="client-header">
                <div className="client-image">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/images/client-hisila.png`}
                    alt="Client Hisila Nakarmi"
                  />
                </div>
                <div className="client-quote-title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="92.68"
                    height="73.38"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                    />
                  </svg>
                  <h3>Absolutely Yummy</h3>
                </div>
              </div>
              <div className="client-quote-container">
                <div className="client-quote">
                  <p>
                    'Makkusé', actually justifies its name…’absolutely yummy’
                    What I like the most is, its originality, that gives
                    original-traditional smell with ethnic taste. In love with
                    its variation & all sooo delicious.
                  </p>
                </div>
                <div className="review-head">
                  <p>
                    <strong>Hisila Nakarmi</strong>
                  </p>
                  <p>
                    <strong>Kathmandu, Nepal</strong>
                  </p>
                </div>
                {/* <!-- <div className="review-subhead">
        <p>Food Review Channel</p>
      </div>
      <div className="review-platform">
        <p><em>Via Instagram</em></p>
      </div> --> */}
              </div>
            </div>
          </div>
        </Slider>

        <div className="product-images">
          <div className="image-container">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/images/slider-product1.png`}
              alt="Product"
            />
          </div>
          <div className="image-container">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/images/happy-kid.jpg`}
              alt="Happy Kid"
            />
          </div>
        </div>
        <div className="button-container1">
          <Link to="/testimonial">
            <button className="btn-main">See all Testimonials</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
