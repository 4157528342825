import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";
import Packaging from "./Packaging";
/* import ourstoryBanner from "../../images/makkuse-lux.jpeg";
import ourstoryBanner1 from "../../images/festive-banner.jpg";
import productImage from "../../images/product-image.png"; */
export default function CustomizedGifting() {
  const { id } = useParams();
  const [propData, setPropData] = useState({});
  const [showform, setShowform] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (id === "product-festive-gifting") {
      document.title = "Festive Gifting | Makkusé";
      setPropData({
        bannerTitle: "Festive Gifting",
        bannerImg: `${process.env.REACT_APP_BASE_URL}/images/festive-banner.jpg`,
        bannerDetail: "",
      });
    } else {
      document.title = "Corporate Boxes | Makkusé";
      setPropData({
        bannerTitle: "Corporate Boxes",
        bannerDetail: "",
        bannerImg: `${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`,
      });
    }
  }, [id]);
  useEffect(() => {
    if (showform) {
      document.body.classList.add("modal-opened");
    } else {
      document.body.classList.remove("modal-opened");
    }
  }, [showform]);

  useEffect(() => {
    if (submitCount) {
      async function sendMail() {
        try {
          const response = await axios.post(
            "https://api.uniboard.unitechmedia.com.np/api/makkuseOrders",
            {
              name: name,
              phone: phone,
              email: email,
              message: message,
            }
          );
          if (response.data) {
            setSubmitCount(0);
            setIsPending(false);
            setEmail("");
            setName("");
            setPhone("");
            setMessage("");
          }
          // console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
      sendMail();
    }
  }, [submitCount]);
  function handleFormSubmit(e) {
    e.preventDefault();
    setIsPending(true);
    setSubmitCount((prevCount) => prevCount + 1);
  }
  return (
    <main className="page-product-details">
      <SecondaryBanner bannerInfo={propData} />
      <section className="section-product-details">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="text-container text-container-custom">
                {id === "product-corporate-boxes" && (
                  <div className="para">
                    <p>
                      We design custom luxury gift boxes for corporate houses
                      and companies according to the brand identity of the
                      companies and specifications provided. The boxes can be
                      customized in large numbers and various palettes of colors
                      and combinations of products can be adjusted in these gift
                      boxes. We’ve already created, designed and rendered
                      extraordinarily beautiful custom boxes for various reputed
                      companies.
                    </p>
                  </div>
                )}
                {id === "product-festive-gifting" && (
                  <div className="para">
                    <p>
                      We’ve designed and produced festive gift boxes for
                      customers to give to their loved ones during various
                      festivals like Teej, Bhai Tika, Mothers’ Day, Fathers’
                      Day, Tihar etc. We incorporate the elegant colors of
                      Nepali festivities into our luxurious brand and we keep
                      the quality and essence of the festivals intact while we
                      customize the gift boxes. Authenticity, luxury and
                      auspiciousness of the Nepali culture are always our sole
                      priority during production.
                    </p>
                  </div>
                )}
              </div>
              <div className="icon-section">
                <h5>Share:</h5>
                <div className="icon-container">
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdev.makkuse.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18.3 0.5H2.2C1.61722 0.499999 1.05824 0.731235 0.645778 1.14295C0.233312 1.55467 0.00105961 2.11322 0 2.696L0 18.804C0 19.3875 0.231785 19.9471 0.644365 20.3596C1.05695 20.7722 1.61652 21.004 2.2 21.004H8.48V14.03H5.594V10.75H8.477V8.25C8.477 5.406 10.17 3.835 12.763 3.835C13.6143 3.84664 14.4635 3.9205 15.304 4.056V6.85H13.873C13.6291 6.81752 13.3811 6.84031 13.1472 6.9167C12.9134 6.99309 12.6997 7.12111 12.522 7.29129C12.3444 7.46146 12.2073 7.66943 12.1209 7.89978C12.0345 8.13013 12.001 8.37696 12.023 8.622V10.75H15.17L14.67 14.03H12.023V21H18.3C18.8835 21 19.4431 20.7682 19.8556 20.3556C20.2682 19.9431 20.5 19.3835 20.5 18.8V2.696C20.4989 2.11322 20.2667 1.55467 19.8542 1.14295C19.4418 0.731235 18.8828 0.499999 18.3 0.5Z" />
                    </svg>
                  </a>
                </div>

                <div className="icon-container">
                  <a
                    href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdev.makkuse.com"
                    rel="noopener"
                  >
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 21 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18.3239 0.5H2.22393C1.64114 0.499999 1.08217 0.731235 0.669703 1.14295C0.257238 1.55467 0.0249854 2.11322 0.0239258 2.696L0.0239258 18.804C0.0239258 19.3875 0.255711 19.9471 0.668291 20.3596C1.08087 20.7722 1.64045 21.004 2.22393 21.004H18.3239C18.9074 21.004 19.467 20.7722 19.8796 20.3596C20.2921 19.9471 20.5239 19.3875 20.5239 18.804V2.696C20.5229 2.11322 20.2906 1.55467 19.8781 1.14295C19.4657 0.731235 18.9067 0.499999 18.3239 0.5ZM16.0899 7.767C16.0989 7.895 16.0989 8.028 16.0989 8.156C16.1068 9.27955 15.8913 10.3935 15.4649 11.433C15.0386 12.4725 14.4099 13.417 13.6154 14.2115C12.8209 15.006 11.8765 15.6346 10.8369 16.061C9.79738 16.4873 8.68347 16.7028 7.55993 16.695C5.92694 16.6985 4.32751 16.2316 2.95293 15.35C3.19302 15.3761 3.43442 15.3885 3.67593 15.387C5.02615 15.39 6.33816 14.9389 7.40093 14.106C6.77472 14.0946 6.16774 13.8877 5.66482 13.5145C5.16191 13.1412 4.7882 12.6201 4.59593 12.024C5.04608 12.1015 5.50754 12.0827 5.94993 11.969C5.27113 11.8304 4.66121 11.4612 4.22372 10.924C3.78624 10.3868 3.54817 9.71479 3.54993 9.022V8.985C3.96478 9.21716 4.42886 9.3474 4.90393 9.365C4.49243 9.09069 4.15513 8.71891 3.92204 8.28274C3.68895 7.84657 3.5673 7.35954 3.56793 6.865C3.56446 6.3328 3.70436 5.80948 3.97293 5.35C4.72636 6.27789 5.66658 7.03688 6.73249 7.57768C7.79841 8.11848 8.96618 8.42898 10.1599 8.489C10.0115 7.84518 10.0791 7.1704 10.3525 6.56887C10.6258 5.96734 11.0896 5.47254 11.6721 5.16088C12.2547 4.84921 12.9237 4.73804 13.5758 4.84452C14.2279 4.951 14.8267 5.26921 15.2799 5.75C15.9504 5.62112 16.5934 5.37647 17.1799 5.027C16.9561 5.71961 16.4875 6.30692 15.8619 6.679C16.4588 6.61123 17.042 6.45379 17.5919 6.212C17.1843 6.81279 16.6762 7.33883 16.0899 7.767Z" />
                    </svg>
                  </a>
                </div>
                <div className="icon-container">
                  <a
                    href="https://www.linkedin.com/company/makkuse"
                    target="blank"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="23"
                        height="23"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="image-section-custom">
                <div className="image-container">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/images/product-image.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Packaging id={id} />
      <section
        className={
          showform ? "section-form sec-form show-form" : "section-form sec-form"
        }
      >
        <div className="overlay" onClick={() => setShowform(false)}></div>
        <div className="order-banner">
          <h3>Do you want to order for your occasion?</h3>
          <button
            className="btn-main btn-order"
            onClick={() => setShowform(true)}
          >
            ORDER NOW
          </button>
        </div>
        <div className="container">
          <div className="close-icon" onClick={() => setShowform(false)}>
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4998 2.9165C14.6155 2.9165 11.796 3.7718 9.39778 5.37424C6.99956 6.97668 5.13038 9.25428 4.0266 11.919C2.92282 14.5838 2.63402 17.516 3.19673 20.3449C3.75943 23.1738 5.14835 25.7723 7.18787 27.8118C9.22739 29.8513 11.8259 31.2403 14.6548 31.803C17.4837 32.3657 20.4159 32.0769 23.0806 30.9731C25.7454 29.8693 28.023 28.0001 29.6254 25.6019C31.2279 23.2037 32.0832 20.3841 32.0832 17.4998C32.0832 15.5847 31.706 13.6884 30.9731 11.919C30.2402 10.1497 29.166 8.54205 27.8118 7.18786C26.4576 5.83368 24.85 4.75948 23.0806 4.02659C21.3113 3.29371 19.415 2.9165 17.4998 2.9165ZM17.4998 29.1665C15.1924 29.1665 12.9368 28.4823 11.0182 27.2003C9.09962 25.9184 7.60427 24.0963 6.72125 21.9645C5.83823 19.8327 5.60719 17.4869 6.05735 15.2238C6.50751 12.9607 7.61865 10.8819 9.25026 9.25026C10.8819 7.61865 12.9607 6.5075 15.2238 6.05734C17.4869 5.60718 19.8327 5.83822 21.9645 6.72124C24.0963 7.60427 25.9184 9.09961 27.2003 11.0182C28.4823 12.9368 29.1665 15.1924 29.1665 17.4998C29.1665 20.594 27.9373 23.5615 25.7494 25.7494C23.5615 27.9373 20.594 29.1665 17.4998 29.1665Z"
                fill="#874F47"
              />
              <path
                d="M21.4522 13.5481C21.3167 13.4114 21.1554 13.3029 20.9777 13.2289C20.8 13.1548 20.6093 13.1167 20.4168 13.1167C20.2243 13.1167 20.0337 13.1548 19.856 13.2289C19.6783 13.3029 19.517 13.4114 19.3814 13.5481L17.5002 15.4439L15.6189 13.5481C15.3443 13.2735 14.9718 13.1192 14.5835 13.1192C14.1951 13.1192 13.8227 13.2735 13.5481 13.5481C13.2735 13.8227 13.1192 14.1951 13.1192 14.5835C13.1192 14.9718 13.2735 15.3443 13.5481 15.6189L15.4439 17.5002L13.5481 19.3814C13.4114 19.517 13.3029 19.6783 13.2289 19.856C13.1548 20.0337 13.1167 20.2243 13.1167 20.4168C13.1167 20.6093 13.1548 20.8 13.2289 20.9777C13.3029 21.1554 13.4114 21.3167 13.5481 21.4522C13.6836 21.5889 13.8449 21.6974 14.0226 21.7715C14.2004 21.8455 14.391 21.8836 14.5835 21.8836C14.776 21.8836 14.9666 21.8455 15.1443 21.7715C15.322 21.6974 15.4833 21.5889 15.6189 21.4522L17.5002 19.5564L19.3814 21.4522C19.517 21.5889 19.6783 21.6974 19.856 21.7715C20.0337 21.8455 20.2243 21.8836 20.4168 21.8836C20.6093 21.8836 20.8 21.8455 20.9777 21.7715C21.1554 21.6974 21.3167 21.5889 21.4522 21.4522C21.5889 21.3167 21.6974 21.1554 21.7715 20.9777C21.8455 20.8 21.8836 20.6093 21.8836 20.4168C21.8836 20.2243 21.8455 20.0337 21.7715 19.856C21.6974 19.6783 21.5889 19.517 21.4522 19.3814L19.5564 17.5002L21.4522 15.6189C21.5889 15.4833 21.6974 15.322 21.7715 15.1443C21.8455 14.9666 21.8836 14.776 21.8836 14.5835C21.8836 14.391 21.8455 14.2004 21.7715 14.0226C21.6974 13.8449 21.5889 13.6836 21.4522 13.5481Z"
                fill="#874F47"
              />
            </svg>
          </div>
          <div className="header-box underline">
            <h4>Write to us for your Customized Gifting</h4>
          </div>
          <form className="form-container" onSubmit={handleFormSubmit}>
            <div className="form-row">
              <div className="form-name">
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>
              <div className="form-phone">
                <input
                  type="text"
                  placeholder="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  required
                />
              </div>
            </div>
            <div className="form-email">
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </div>
            <div className="form-message">
              <textarea
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              ></textarea>
            </div>
            <div className="form-submit">
              <div className="form-submit">
                <button
                  className="btn-main"
                  disabled={isPending ? true : false}
                >
                  {isPending ? "Submitting" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ShopModal />
    </main>
  );
}
