import React from "react";
/* import gundpakCookies from "../images/gundpak-cookies-one-piece.png";
import chocolateFudge from "../images/media-slider-4.png";
import sevenPack from "../images/chocolate-fudge-seven-pack.png";
import fourteenPack from "../images/chocolate-fudge-fourteen-pack.png";
import smallPustakari from "../images/pustakari-small.png";
import bigPustakari from "../images/pustakari-big.png";
import largeOatmeal from "../images/oatmeal-one-piece.png";
import oatmealSixPack from "../images/oatmeal-six-pack.png";
import oatmealSevenPack from "../images/oatmeal-seven-pack-small.png";
import oatmealFourteenPack from "../images/oatmeal-fourteen-pack.png";
import cookiesSemolina7 from "../images/cookies-semolina-14pcs.jpg";
import semolina7 from "../images/semolina-7-pack.jpg";
import combo from "../images/2-3-combo.png";
import mocha from "../images/mocha-200g.png";
import mochaGundpak from "../images/mocha-gundpak-2.png";
import rosewater2 from "../images/rosewater-2.png";
import sugarFreeGundpak from "../images/sugar-free-gundpak.jpg";
import chocolateFudge3 from "../images/chocolate-fudge-3.png";
import oatmealGundpakCookies from "../images/oatmeal-gundpak-cookies-2.png";
import semolinaBannerThumb from "../images/semolina-banner-thumb.jpg"; */

const AvailableBundles = ({ pageId }) => {
  const pageItems = [
    {
      id: "product-chocolate-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/gundpak-cookies-one-piece.png`,
          imageAlt: "Large Size Cookies (1 Piece)",
          title: (
            <p>
              Large Size Cookies <br /> (1 Pc)
            </p>
          ),
          price: "Rs. 120",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/media-slider-4.png`,
          imageAlt: "Large Size Cookies (6 Pack Bag)",
          title: (
            <p>
              Large Size Cookies <br />
              (6 pack Bag)
            </p>
          ),
          price: "Rs. 649",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-seven-pack.png`,
          imageAlt: "7 Pack Small Box Chocolate Fudge Gundpak Cookies",
          title: (
            <p>
              Small Size Cookies <br /> (7 pack Box)
            </p>
          ),
          price: "Rs. 599",
        },
        {
          id: 4,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-fourteen-pack.png`,
          imageAlt: "14 Pack Small Box Chocolate Fudge Gundpak Cookies",
          title: (
            <p>
              Small Size Cookies
              <br /> (14 pack Box)
            </p>
          ),
          price: "Rs. 1149",
        },
      ],
    },
    {
      id: "product-pustakari-detail",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/pustakari-small.png`,
          imageAlt: "Pustakari Small Box (250g)",
          title: <p>Pustakari Small Box (250g)</p>,
          price: "Rs. 529",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/pustakari-big.png`,
          imageAlt: "Pustakari Big box (370g)",
          title: <p>Pustakari Big box (370g)</p>,
          price: "Rs. 649",
        },
      ],
    },
    {
      id: "product-oatmeal-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-one-piece.png`,
          imageAlt: "Oatmeal Gundpak Cookies (1 Pc)",
          title: (
            <p>
              Large Size Cookies <br />
              (1 Pc)
            </p>
          ),
          price: "Rs. 120",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-six-pack.png`,
          imageAlt: "Oatmeal Gundpak Cookies 6 Pack",
          title: (
            <p>
              Large Size Cookies
              <br />
              (6 Pack Bag)
            </p>
          ),
          price: "Rs. 649",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-seven-pack-small.png`,
          imageAlt: "Oatmeal Gundpak Cookies 7 Pack",
          title: (
            <p>
              Small Size Cookies <br />
              (7 Pack Box)
            </p>
          ),
          price: "Rs. 599",
        },
        {
          id: 4,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-fourteen-pack.png`,
          imageAlt: "Oatmeal Gundpak Cookies 14 Pack",
          title: (
            <p>
              Small Size Cookies <br />
              (14 Pack Box)
            </p>
          ),
          price: "Rs. 1149",
        },
      ],
    },
    {
      id: "product-semolina-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/cookies-semolina-14pcs.jpg`,
          imageAlt: "Small Size Cookies",
          title: (
            <p>
              Small Size Cookies <br /> (7 Pack Box)
            </p>
          ),
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-7-pack.jpg`,
          imageAlt: "Small Size Cookies",
          title: (
            <p>
              Small Size Cookies <br /> (14 Pack Box)
            </p>
          ),
          price: "",
        },
      ],
    },
    {
      id: "product-mocha-gundpak",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/mocha-gundpak-latest.jpg`,
          imageAlt: "Mocha Gundpak",
          title: <p>Mocha Gundpak (200g)</p>,
          price: "Rs. 649",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/2-3-combo.png`,
          imageAlt: "Two jar of gundpak",
          title: (
            <p>
              Two Jar Combo Rosewater & Pistachio and Mocha Gundpak (200g each)
            </p>
          ),
          price: "Rs. 1249",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/3-jar-gundpak-combo.jpg`,
          imageAlt: "Three Jar of gundpak",
          title: <p>Three Jar Of Gundpak</p>,
          price: "Rs. 1249",
        },
      ],
    },
    {
      id: "product-rosewater-gundpak",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/rosewater-gundpak1.jpg`,
          imageAlt: "Rosewater",
          title: <p>Rosewater &amp; Pistachio Gundpak (200g)</p>,
          price: "Rs. 649",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/2-3-combo.png`,
          imageAlt: "Two Jar Combo Rosewater",
          title: (
            <p>
              Two Jar Combo Rosewater &amp; Pistachio and Mocha Gundpak (200g
              each)
            </p>
          ),
          price: "Rs. 1249",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/3-jar-gundpak-combo.jpg`,
          imageAlt: "Three Jar of gundpak",
          title: <p>Three Jar Of Gundpak</p>,
          price: "Rs. 1249",
        },
      ],
    },
    {
      id: "product-sugarfree-gundpak",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/mocha-200g.png`,
          imageAlt: "Sugarfree",
          title: <p>Sugarfree Gundpak</p>,
          price: "Rs. 649",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/3-jar-gundpak-combo.jpg`,
          imageAlt: "Three Jar of gundpak",
          title: <p>Three Jar Of Gundpak</p>,
          price: "Rs. 1249",
        },
      ],
    },
    {
      id: "product-combo-2-jar",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/mocha-gundpak-2.png`,
          imageAlt: "Mocha",
          title: <p>Mocha Gundpak</p>,
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/rosewater-2.png`,
          imageAlt: "Rosewater",
          title: <p>Rosewater &amp; Pistacho Gundpak</p>,
          price: "",
        },
      ],
    },
    {
      id: "product-combo-three-jar",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/mocha-gundpak-2.png`,
          imageAlt: "Mocha",
          title: <p>Mocha Gundpak</p>,
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/rosewater-2.png`,
          imageAlt: "Rosewater",
          title: <p>Rosewater &amp; Pistacho Gundpak</p>,
          price: "",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/sugar-free-gundpak.jpg`,
          imageAlt: "Rosewater",
          title: <p>Sugar Free Gundpak</p>,
          price: "",
        },
      ],
    },
    {
      id: "product-combo-7-pack-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-3.png`,
          imageAlt: "Chocolate Gundpak Fudge Cookies",
          title: <p>Chocolate Gundpak Fudge Cookies</p>,
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-gundpak-cookies-2.png`,
          imageAlt: "Oatmeal Gundpak Cookies",
          title: <p>Oatmeal Gundpak Cookies</p>,
          price: "",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-banner-thumb.jpg`,
          imageAlt: "Gundpak Filled Semolina Cookies",
          title: <p>Gundpak Filled Semolina Cookies</p>,
          price: "",
        },
      ],
    },
    {
      id: "product-combo-14-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-3.png`,
          imageAlt: "Chocolate Gundpak Fudge Cookies",
          title: <p>Chocolate Gundpak Fudge Cookies</p>,
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-gundpak-cookies-2.png`,
          imageAlt: "Oatmeal Gundpak Cookies",
          title: <p>Oatmeal Gundpak Cookies</p>,
          price: "",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-banner-thumb.jpg`,
          imageAlt: "Gundpak Filled Semolina Cookies",
          title: <p>Gundpak Filled Semolina Cookies</p>,
          price: "",
        },
      ],
    },
    {
      id: "product-combo-21-pack-cookies",
      bundles: [
        {
          id: 1,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-3.png`,
          imageAlt: "Chocolate Gundpak Fudge Cookies",
          title: <p>Chocolate Gundpak Fudge Cookies</p>,
          price: "",
        },
        {
          id: 2,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-gundpak-cookies-2.png`,
          imageAlt: "Oatmeal Gundpak Cookies",
          title: <p>Oatmeal Gundpak Cookies</p>,
          price: "",
        },
        {
          id: 3,
          imageSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-banner-thumb.jpg`,
          imageAlt: "Gundpak Filled Semolina Cookies",
          title: <p>Gundpak Filled Semolina Cookies</p>,
          price: "",
        },
      ],
    },
  ];
  const renderedItem = pageItems.filter((item) => item.id === pageId);
  return (
    <section class="section-product-information">
      <div class="container">
        <div class="text-container">
          <div class="header-box underline">
            <h2>Available Bundles</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          {renderedItem[0]?.bundles.map((item) => {
            return (
              <div class="col-md-3" key={item.id}>
                <div class="card">
                  <div class="image-container">
                    <div class="overlay"></div>
                    <img src={item.imageSrc} alt={item.imageAlt} />
                  </div>
                  <div class="text-container">
                    <div class="card--title">{item.title}</div>
                    <div class="card--price">
                      <p>{item.price}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AvailableBundles;
