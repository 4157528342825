import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
/* import bannerSliderImage1 from "../../images/banner-slider1.png";
import bannerSliderImage2 from "../../images/banner-slider2.png";
import bannerSliderImage3 from "../../images/banner-slider3.png"; */

import Slider from "react-slick";
// import { Distributor } from "./Distributor";

export default function BannerSlider() {
  // const [showModal, setShowModal] = useState(false);
  // useEffect(() => {
  //   const body = document.querySelector("body");
  //   if (showModal) {
  //     body.classList.add("modal-opened");
  //   } else {
  //     body.classList.remove("modal-opened");
  //   }
  // }, [showModal]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const shopNow = document.querySelector(".shop-now");
    if (showModal) {
      shopNow.classList.add("show");
    } else {
      shopNow.classList.remove("show");
    }
    window.addEventListener("click", () => setShowModal(false));
    document
      .querySelector(".close-icons")
      .addEventListener("click", () => setShowModal(false));
  }, [showModal]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    dots: true,
    infinite: true,
    arrows: false,
  };
  return (
    <section className="section-banner">
      <div className="banner-container">
        {/* <div className="overlay"></div> */}
        <div className="banner--text-container">
          <div className="header-box">
            <h1>Nepali Desserts Redefined</h1>
          </div>
          <div className="sub-head">
            <h5>
              Sharing the authentic luxurious dessert recipes from the Himalayas
            </h5>
          </div>
        </div>
        {/* <div className="banner-slider"> */}
        <Slider {...settings}>
          <div className="content">
            <div className="image-container">
              {/* <img src={bannerSliderImage1} alt="" /> */}
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/banner-slider1.png`}
                alt=""
              />
            </div>
          </div>
          <div className="content">
            <div className="image-container">
              {/* <img src={bannerSliderImage2} alt="" /> */}
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/banner-slider2.png`}
                alt=""
              />
            </div>
          </div>
          <div className="content">
            <div className="image-container">
              {/* <img src={bannerSliderImage3} alt="" /> */}
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`}
                alt=""
              />
            </div>
          </div>
        </Slider>
        {/* </div> */}
      </div>
      <div className="section-info-banner">
        <div className="banner-content">
          <div className="image-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="30px"
              width="33px"
            >
              {/* <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
              <path d="M512 165.4c0 127.9-70.05 235.3-175.3 270.1c-20.04 7.938-41.83 12.46-64.69 12.46c-64.9 0-125.2-36.51-155.7-94.47c-54.13 49.93-68.71 107-68.96 108.1C44.72 472.6 34.87 480 24.02 480c-1.844 0-3.727-.2187-5.602-.6562c-12.89-3.098-20.84-16.08-17.75-28.96c9.598-39.5 90.47-226.4 335.3-226.4C344.8 224 352 216.8 352 208S344.8 192 336 192C228.6 192 151 226.6 96.29 267.6c.1934-10.82 1.242-21.84 3.535-33.05c13.47-65.81 66.04-119 131.4-134.2c28.33-6.562 55.68-6.013 80.93-.0054c56 13.32 118.2-7.412 149.3-61.24c5.664-9.828 20.02-9.516 24.66 .8282C502.7 76.76 512 121.9 512 165.4z" />
            </svg>
          </div>
          <div className="text-container">
            <div className="header-box">
              <p className="sub-title">100% Pure Milk</p>
            </div>
            <div className="para">
              <p className="small-text-white">
                Our Khuwa (thickened milk) arrives fresh from the high altitudes
                of Kavre, Nepal daily to our production center located at
                Kathmandu.
              </p>
            </div>
          </div>
        </div>
        <div className="banner-content">
          <div className="image-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="22.5"
              height="34"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path
                fill="#782f19"
                d="M382 0H130a18 18 0 0 0-18 18v476a18 18 0 0 0 18 18h252a18 18 0 0 0 18-18V18a18 18 0 0 0-18-18ZM148 448V64h216v384Z"
              />
            </svg>
          </div>
          <div className="text-container">
            <div className="header-box">
              <p
                className="sub-title"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Order Now
              </p>
            </div>
            <div className="para">
              <p className="small-text-white">
                Happily Delivering these treats to you,{" "}
                {/* <Link
                  to="#"
                  style={{ color: "#874f47" }}
                  onClick={() => setShowModal(true)}
                >
                  <b>View All Our Distributors.</b>
                </Link> */}
                We accept international card payments, e-wallets, and cash on
                delivery.
              </p>
            </div>
          </div>
          {/* {showModal && (
            <div className="overlay" onClick={() => setShowModal(false)}></div>
          )}
          <Distributor showModal={showModal} setShowModal={setShowModal} /> */}
        </div>
      </div>
    </section>
  );
}
