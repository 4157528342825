import React from "react";
import { Link } from "react-router-dom";

const CardPrimary = ({ image, title, link, alt }) => {
  return (
    <div className="card">
      <Link to={`/${link}`} onClick={() => window.scrollTo(0, 0)}>
        <div className="image-container">
          <img src={image} alt={alt} loading="lazy" />
        </div>
      </Link>
      <div className="text-container">
        <div className="card--title">
          <p className="sub-title">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default CardPrimary;
