import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//Components
import SecondaryBanner from "../../Components/SecondaryBanner";
import SideBar from "../../Components/SideBar";
import OtherSpecial from "../../Components/OtherSpecial";
//Images and assets
import arrow from "../../images/arrow-down.svg";
import ShopModal from "../../Components/ShopModal";
/* import allProductBanner from "../../images/product-banner.png";
import pustakariGeneralBanner from "../../images/pustakari-general-banner.png";
import cookiesBanner from "../../images/chocolate-banner.png";
import gundpakBanner from "../../images/gundpak-general-banner.jpg";
import specialPackBanner from "../../images/combo-banner-thumb.jpg";
import luxuryBanner from "../../images/banner-slider3.png";
import chocolateFudge from "../../images/chocolate-fudge-3.png";
import semolinaCookies from "../../images/semolina-banner-thumb.jpg";
// import mochaGundpak from "../../images/mocha-gundpak-2.png";
import oatmealCookies from "../../images/oatmeal-gundpak-cookies-2.png";
import pustakari from "../../images/gallery-thumbnail6.png";
import roseWaterGundpak from "../../images/rosewater-gundpak1.jpg";
import mochaGundpakLatest from "../../images/mocha-gundpak-latest.jpg";
import sugarFreeGundpak from "../../images/sugar-free-gundpak.jpg";
import twoJarGundpak from "../../images/2-3-combo.png";
import threeJarGundpak from "../../images/3-jar-gundpak-combo.jpg";
import sevenPackCookies from "../../images/small-gift-box.png";
import fourteenPackCookies from "../../images/small-gift-box.png";
import cookiesPack21 from "../../images/21-pack-thumb-banner.jpg";
import luxuryBox from "../../images/gallery-2.png";
import smallGiftBox from "../../images/small-box-thumb-banner.jpg"; */

const Product = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedText, setSelectedText] = useState("Alphabetically");
  const [showOtherSection, setShowOtherSection] = useState(false);
  const [secondayBanner, setSecondayBanner] = useState({
    bannerTitle: "",
    bannerDetail: "",
    bannerImg: ``,
    page: "",
  });
  const location = useLocation();
  const [products, setProducts] = useState([
    {
      id: 1,
      name: "Chocolate Fudge Gundpak Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/chocolate-fudge-3.png`,
      link: "/products/product-chocolate-cookies",
      productType: "cookies",
      category: "all",
    },
    {
      id: 2,
      name: "Gundpak Filled Semolina Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/semolina-banner-thumb.jpg`,
      link: "/products/product-semolina-cookies",
      productType: "cookies",
      category: "all",
    },
    {
      id: 3,
      name: "Mocha Gundpak",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/mocha-gundpak-latest.jpg`,
      link: "/products/product-mocha-gundpak",
      productType: "gundpak",
      category: "all",
    },
    {
      id: 4,
      name: "Oatmeal Gundpak Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/oatmeal-gundpak-cookies-2.png`,
      link: "/products/product-oatmeal-cookies",
      productType: "cookies",
      category: "all",
    },
    {
      id: 5,
      name: "Pustakari",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail6.png`,
      link: "/products/product-pustakari-detail",
      productType: "pustakari",
      category: "all",
    },
    {
      id: 6,
      name: "Rosewater & Pistacho Gundpak",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/rosewater-gundpak1.jpg`,
      link: "/products/product-rosewater-gundpak",
      productType: "gundpak",
      category: "all",
    },
    {
      id: 7,
      name: "Sugar Free Gundpak",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/sugar-free-gundpak.jpg`,
      link: "/products/product-sugarfree-gundpak",
      productType: "gundpak",
      category: "all",
    },
    {
      id: 8,
      name: "The Ultimate Luxury Box",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/gallery-2.png`,
      link: "/products/product-luxury-box-detail",
      productType: "luxury-box",
      category: "combo",
    },
    {
      id: 9,
      name: "Small Gift Box",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/small-box-thumb-banner.jpg`,
      link: "/products/product-small-gift-box-detail",
      productType: "small-gift",
      category: "combo",
    },
    {
      id: 10,
      name: "Two Jar Of Gundpak",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/2-3-combo.png`,
      link: "/products/product-combo-2-jar",
      productType: "special",
      category: "combo",
    },
    {
      id: 11,
      name: "Three Jar Of Gundpak",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/3-jar-gundpak-combo.jpg`,
      link: "/products/product-combo-three-jar",
      productType: "special",
      category: "combo",
    },
    {
      id: 12,
      name: "7 Pack Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/seven-pack-banner.jpg`,
      link: "/products/product-combo-7-pack-cookies",
      productType: "special",
      category: "combo",
    },
    {
      id: 13,
      name: "14 Pack Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/fourteen-pack-banner.jpg`,
      link: "/products/product-combo-14-cookies",
      productType: "special",
      category: "combo",
    },
    {
      id: 14,
      name: "21 Pack Cookies",
      imgSrc: `${process.env.REACT_APP_BASE_URL}/images/21-pack-thumb-banner.jpg`,
      link: "/products/product-combo-21-pack-cookies",
      productType: "special",
      category: "combo",
    },
  ]);
  const [renderedProducts, setRenderedProducts] = useState([]);
  useEffect(() => {
    // window.scrollTo(0, 0);
    const body = document.querySelector("body");
    const hideDropDown = () => {
      setShowDropDown(false);
    };
    body.addEventListener("click", hideDropDown);
    return () => body.removeEventListener("click", hideDropDown);
  }, []);

  const sortDescending = () => {
    renderedProducts.sort((a, b) => b.name.localeCompare(a.name));
  };
  const sortAscending = () => {
    renderedProducts.sort((a, b) => a.name.localeCompare(b.name));
  };
  useEffect(() => {
    if (location.pathname === "/product") {
      setShowOtherSection(true);
    } else {
      setShowOtherSection(false);
    }
  }, [location.pathname]);
  useEffect(() => {
    window.scrollTo(0, 0);
    switch (location.pathname) {
      case "/product":
        setRenderedProducts(() =>
          products.filter((product) => product.category === "all")
        );
        document.title = "All Products | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Nostalgic, Luxurious & Delectable",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/product-banner.jpg`,
          page: "all-product",
        });
        break;
      case "/product-pustakari":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "pustakari")
        );
        document.title = "Pustakari | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Pustakari",
          bannerDetail:
            "A traditional hard candy made from thickened milk, fried and mixed with sugar and shaped into dainty portions.",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/pustakari-general-banner.png`,
          page: "",
        });
        break;
      case "/product-cookies":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "cookies")
        );
        document.title = "Cookies | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Cookies",
          bannerDetail:
            "Delish baked delicacies handcrafted and infused with exotic flavors of chocolate, cranberry and filled with Gundpak.",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/chocolate-banner.png`,
          page: "cookies",
        });
        break;
      case "/product-gundpak":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "gundpak")
        );
        document.title = "Gundpak | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Gundpak",
          bannerDetail:
            "An authentic Nepali fudge dessert made with thickened milk, a natural thickening agent and watermelon seeds",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/gundpak-general-banner.jpg`,
          page: "",
        });
        break;
      case "/product-other-special":
        setRenderedProducts(() =>
          products.filter((product) => product.category === "combo")
        );
        document.title = "Combo Boxes | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Nostalgic, Luxurious & Delectable",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/product-banner.jpg`,
          page: "combo-boxes",
        });
        break;
      case "/product-combo-pack":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "special")
        );
        document.title = "Special Packs | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Special Packs",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/combo-banner-thumb.jpg`,
          page: "",
        });
        break;
      case "/product-luxury-box":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "luxury-box")
        );
        document.title = "The Ultimate Luxury Box | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "The Ultimate Luxury Box",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`,
          page: "",
        });
        break;
      case "/product-small-gift-box":
        setRenderedProducts(() =>
          products.filter((product) => product.productType === "small-gift")
        );
        document.title = "Small Gift Box | Makkusé";
        setSecondayBanner({
          ...secondayBanner,
          bannerTitle: "Small Gift Boxs",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/small-box-thumb-banner.jpg`,
          page: "small-gift",
        });
        break;
      default:
        return;
    }
  }, [location.pathname]);

  return (
    <main className="page-products">
      <SecondaryBanner bannerInfo={secondayBanner} />
      <section className="section-products all-products">
        <div className="container">
          <SideBar />
          <>
            <div className="card-container">
              <div className="menu-container1">
                <div className="text-container">
                  <p className="sub-title">
                    {renderedProducts.length === 1
                      ? `${renderedProducts.length} Product`
                      : `${renderedProducts.length} Products`}
                  </p>
                </div>
                <div className="filter-container">
                  <div className="filter-label">
                    <p>Sort by</p>
                  </div>
                  <div
                    className="select-container"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropDown(!showDropDown);
                    }}
                  >
                    <ul
                      tabIndex={0}
                      className={`dropdown-select ${
                        showDropDown && "show-dropdown"
                      }`}
                    >
                      <p className="selected">{selectedText}</p>
                      <li
                        tabIndex={0}
                        className="is-checked name"
                        data-sort-value="name"
                        onClick={(e) => {
                          setSelectedText("Ascending");
                          sortAscending();
                        }}
                      >
                        Ascending
                      </li>
                      <li
                        tabIndex={0}
                        data-sort-value="descending"
                        className="descending"
                        onClick={(e) => {
                          setSelectedText("Descending");
                          sortDescending();
                        }}
                      >
                        Descending
                      </li>
                    </ul>
                    <div className="drop-arrow">
                      <img src={arrow} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-row">
                <ul>
                  <div className="grid">
                    {/* <div className="grid-sizer"></div>
                    <div className="gutter-sizer"></div> */}
                    {renderedProducts.map((product) => {
                      return (
                        <div className="grid-item" key={product.id}>
                          <li>
                            <Link
                              to={product.link}
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              <div className="card">
                                <div className="image-container">
                                  <div className="overlay"></div>
                                  <img
                                    src={product.imgSrc}
                                    alt={product.name}
                                  />
                                </div>
                                <div className="text-container">
                                  <div className="card--title">
                                    <p className="name descending">
                                      {product.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </div>
                      );
                    })}
                  </div>
                </ul>
              </div>
              {showOtherSection && <OtherSpecial />}
            </div>
          </>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};
export default Product;
