import React, { useEffect } from "react";
import SecondaryBanner from "../Components/SecondaryBanner";
import ShopModal from "../Components/ShopModal";

const MakkuseBlogs = () => {
  useEffect(() => {
    document.title = "Makkusé Blogs | Makkusé";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "Makkusé Blogs",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/story.png`,
      page: "cookies",
    },
  };
  return (
    <main className="blogs">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <section className="section-blog" style={{ marginBlock: "100px" }}>
        <div className="container">
          <div className="sub-topic">
            <h2 style={{ fontWeight: "400" }}>
              There are so many exciting conversations waiting to happen within
              the Nepali dessert space – here are some thoughts from our team,
              clients and enthusiasts! Do have a read and leave your thoughts!
            </h2>
          </div>
          <div className="text-container">
            <div className="para">
              <p>
                Walking down the alleys of Patan with my dad, after a long stay
                abroad for 5-6 years, something so familiar yet distanced from
                me hit my senses. I could smell my childhood and I got all
                nostalgic from the smell of the delicious hard candies me and my
                friends used to have during our childhood. Nostalgia hits you
                hard, sometimes in small packages. I asked my dad if those
                candies are still the same. He replied,” We don’t get the same
                flavor anymore though, change is inevitable, don’t you think?”
                “What about the Pustakari shop round that corner?” I asked as I
                pointed towards the cranny of that alley. “Why don’t we find
                out?”, we went towards the corner of the road and could see the
                very shop where we as children used to go for Pustakari and
                Gundpak. Uncle has grown some gray hairs, but the same handsome
                man grinds 8 hours a day, preparing the most delicious Pustakari
                and Gundpak for us. We went up to the shop, had a little chat,
                uncle could remember our gang but not me personally. He asked
                about my friends, I told him about them, most of them are
                abroad. We asked him for some Pustakari and a packet of Gundpak.
                Similar packets but the taste has changed. Our host insisted
                about the shortage of pure milk products in the market. We
                agreed about the market, its inflation and how everything has
                lost its quality, its touch. We went back home, with some
                nostalgia inside our pockets and talked our way back home about
                how other delicacies of Patan have changed through the years. I
                came back to Nepal after quite a long time and also, my trip
                here was to be a short one. I wanted to take my childhood
                memories back to the States, more in a visible form, in small
                delectable sizes and obviously in such a packaging that it would
                be no problem to get through customs at the airport. I asked my
                friends if they knew some place which gives us a good labeled
                packaging for these hard candies and gundpak, which everyone
                loves to have their hands on. Many of my friends told me about
                Makkuse, the packaging Makkuse offers for their products, the
                quality of the produce and the original taste of these treats
                all combined with new and innovative touch. I wanted to try this
                brand out. We ordered a few jars of Gundpak and a box of
                Pustakari. We got the delivery the very next day. We were
                astounded by the packaging they had done for the products and
                the taste, delicately achieved, similar to the ones we used to
                have in our childhood. All of us at the dinner table, ran our
                eyes to each other while trying these products out. Everyone was
                enjoying the treats with whole heart, happy and everybody having
                a glance over their shoulders if they can get another candy from
                besides them. We decided to order some more boxes of Pustakari,
                a few combos of Gundpak jars and some more boxes of cookies'
                boxes, as they had Gundpak in them too. I was so pleased with
                the customer service and the service they provide for the
                delivery. I decided to take these boxes and packages abroad to
                my Nepali and foreign friends. After a long tiring flight, I was
                very excited to share these to my friends. Without a doubt,
                everyone loved the taste and some of my Nepali friends were just
                so pleased to have the nostalgia hit them in the form of these
                delish candies. After a feast of these authentic Nepali
                desserts, we planned that whoever goes to Nepal shall bring
                these treats back to the States with them. We enquired Makkuse
                through social media and we were pleased to know that they were
                planning to add an international shipping facility too. Couldn’t
                be more impressed by a Nepali brand, as they have done their
                part in taking the authentic Nepali luxury dessert to the global
                stage. All my best wishes to Makkuse, godspeed!
              </p>
              <br />
              <br />
              <p>
                <strong>Avish, Oklahoma, USA</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};

export default MakkuseBlogs;
