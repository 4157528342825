import React from "react";
import { Link } from "react-router-dom";
/* import galleryThumbNail1 from "../../images/gallery-thumbnail1.png";
import galleryThumbNail2 from "../../images/gallery-thumbnail2.png";
import galleryThumbNail3 from "../../images/gallery-thumbnail3.png";
import galleryThumbNail4 from "../../images/gallery-thumbnail4.png";
import galleryThumbNail5 from "../../images/gallery-thumbnail5.png";
import galleryThumbNail6 from "../../images/gallery-thumbnail6.png"; */
const GalleryHome = () => {
  return (
    <section className="section-gallery">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail1}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail1.png`}
                alt="Makkusé Cookies"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-6 gallery-hide">
            <div className="stay-touch-container">
              <div className="text-container underline">
                <h2>Stay in touch</h2>
              </div>
              <div className="button-container">
                <a href="https://www.instagram.com/makkuse.np/" target="blank">
                  <div className="bt1">
                    <button className="btn-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width="17.43"
                        height="17.43"
                        fill="white"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                      makkuse.np
                    </button>
                  </div>
                </a>
                <a
                  href="https://www.facebook.com/makkuse.np"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="bt1">
                    <button className="btn-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        height="17.43"
                        width="17.43"
                        fill="white"
                      >
                        <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                      </svg>
                      makkuse.np
                    </button>
                  </div>
                </a>
              </div>
              <div className="a-container">
                <div className="a1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.15"
                    height="17.07"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mail"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  <a
                    href="mailto:info.makkuse@gmail.com"
                    className="another-a"
                    target="blank"
                  >
                    info@makkuse.com
                  </a>
                </div>
                <div className="a2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-phone"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                  </svg>
                  <a
                    href="tel:+9779862265754"
                    target="blank"
                    className="another-a"
                  >
                    +977 9862265754
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail2}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail2.png`}
                alt="Makkusé Pustakari"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail3}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail3.png`}
                alt="People eating Makkusé cookies"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail4}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail4.png`}
                alt="Makkusé Chocolate Fudge Gudpak"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail5}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail5.png`}
                alt="Makkusé Cookies"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div className="image-container">
              <img
                // src={galleryThumbNail6}
                src={`${process.env.REACT_APP_BASE_URL}/images/gallery-thumbnail6.png`}
                alt="Makkusé Pustakari"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="button-container1">
          <Link to="/gallery" onClick={() => window.scrollTo(0, 0)}>
            <button className="btn-main">View all Gallery</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default GalleryHome;
